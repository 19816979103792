export const environment = {
  production: true,
 // BASE_URL: 'http://localhost:4200',
//endpoint: 'http://localhost:8080/Exam360/rest',
endpoint: 'http://143.110.184.183:8080/Exam360/rest'
 //endpoint1: 'http://localhost/',
 //endpoint: 'http://202.122.23.70:9093/Exam360/rest'
  //endpoint: 'http://10.20.20.12:8080/Exam360/rest'
//endpoint: 'http://ugam.uasbangalore.edu.in:8083/Exam360/rest'
//176 server pointing
//endpoint: 'http://202.122.23.70:8003/Exam360/rest'
//endpoint: 'http://ugam.uasbangalore.edu.in:8080/Exam360/rest',
//endpoint: 'https://ugam.uasbangalore.edu.in:9443/Exam360/rest',
//product endpoint: 'https://ugam.uasbangalore.edu.in:9444/Exam360/rest'
};
