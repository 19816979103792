
import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { ConfigurationdropdownComponent } from 'src/app/pages/authentication/configurationdropdown/configurationdropdown.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UpdatestudentphotoComponent } from 'src/app/components/student/updatestudentphoto/updatestudentphoto.component';
import { ViewmoreComponent } from 'src/app/components/student/viewmore/viewmore.component';
import { ChangepasswordComponent } from 'src/app/components/student/changepassword/changepassword.component';
import { ServiceapiService } from 'src/app/serviceapi.service';

var body = document.getElementsByTagName("body")[0];

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  userDetails: any;
  imagesProfile:any;
  userProfile:any;
  rows: any = [];
  temp: any = [];
  public menuItems: Menu[];
  public items: Menu[];
  showconfig:boolean=false;
  updatephoto:boolean=false;
  updatephoto1:boolean=false;
  public searchResult: boolean = false;
  public searchResultEmpty: boolean = false;
  public openNav: boolean = false
  public right_sidebar: boolean = false
  public text: string;
  public elem:any;
  public isOpenMobile: boolean = false
  @Output() rightSidebarEvent = new EventEmitter<boolean>();

  constructor(
    public navServices: NavService,
    public dialog: MatDialog,
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    private translate: TranslateService,
    private serviceapi: ServiceapiService) {
  }


  ngOnDestroy() {
    this.removeFix();
  }


  right_side_bar() {
    this.right_sidebar = !this.right_sidebar
    this.rightSidebarEvent.emit(this.right_sidebar)
  }

  collapseSidebar() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar
  }

  openMobileNav() {
    this.openNav = !this.openNav;
  }

  public changeLanguage(lang:any) {
    this.translate.use(lang)
  }

  searchTerm(term: any) {
    term ? this.addFix() : this.removeFix();
    if (!term) return this.menuItems = [];
    let items :any = [];
    term = term.toLowerCase();
    this.items.filter((menuItems:any) => {
      if (menuItems.title.toLowerCase().includes(term) && menuItems.type === 'link') {
        items.push(menuItems);
      }
      if (!menuItems.children) return false
      menuItems.children.filter((subItems:any) => {
        if (subItems.title.toLowerCase().includes(term) && subItems.type === 'link') {
          subItems.icon = menuItems.icon
          items.push(subItems);
        }
        if (!subItems.children) return false
        subItems.children.filter((suSubItems:any) => {
          if (suSubItems.title.toLowerCase().includes(term)) {
            suSubItems.icon = menuItems.icon
            items.push(suSubItems);
          }
        })

        return
      })
      this.checkSearchResultEmpty(items)
      this.menuItems = items
      return
    });
    return
  }

  checkSearchResultEmpty(items:any) {
    if (!items.length)
      this.searchResultEmpty = true;
    else
      this.searchResultEmpty = false;
  }

  addFix() {
    this.searchResult = true;
    body.classList.add("offcanvas");
  }

  removeFix() {
    this.searchResult = false;
    body.classList.remove("offcanvas");
    this.text = "";
  }
  ngOnInit() {
    this.elem = document.documentElement;
    this.navServices.items.subscribe(menuItems => {
      this.items = menuItems
    });

    this.userDetails = localStorage.getItem('currentUser');
    this.imagesProfile=JSON.parse(this.userDetails)[0].image_url;
    this.userProfile=JSON.parse(this.userDetails)[0].username;

    if(JSON.parse(this.userDetails)[0].loginType=='staff'){
      this.showconfig=false
      this.updatephoto=false
    }
    if(JSON.parse(this.userDetails)[0].loginType=='regular'){
      this.showconfig=true
      this.updatephoto=false
    }
    if(JSON.parse(this.userDetails)[0].loginType=='student'){
      this.showconfig=false
     this.updatephoto=true
      if(JSON.parse(this.userDetails)[0].degreeid==2){
        this.updatephoto1=true
      }else{
        this.updatephoto1=false
      }
      
    }

    this.loadAlert();
  }


  notificationtitle: any
  notificationsmessage: any
  notifications = new Array()
  notificationslength: any;
  loadAlert() {
      this.serviceapi.loadAlert(JSON.parse(this.userDetails)[0].studentId,JSON.parse(this.userDetails)[0].collegeid, JSON.parse(this.userDetails)[0].programmeyearid,JSON.parse(this.userDetails)[0].academicyearid, JSON.parse(this.userDetails)[0].semesterid, )
          .subscribe(resp => {
             
              this.rows = resp.body;
              if (this.rows.status == 1) {
                  this.notifications = this.rows.details;
                  this.notificationslength = this.rows.details.length;
                  this.notificationtitle = this.rows.details[0].title;
                  this.notificationsmessage = this.rows.details[0].message;
                 
              }

              if (this.rows.status == 3) {
               this.notifications = [];
             
                this.notificationtitle = "";
                this.notificationsmessage = "";
                  this.notificationslength=0;
                  //this.openSnackBar(this.rows.message, "INFO");
              }
          });
  }

  updatealert(notf) {
      if (window.confirm(notf.message)) {
        
          this.serviceapi.updateAlert(JSON.parse(this.userDetails)[0].studentId, notf.alertId)
              .subscribe(resp => {
                
                  this.rows = resp.body;
                  if (this.rows.status == 1) {
                      this.loadAlert()
                  } else {
                      //this.openSnackBar(this.rows.message, "INFO");
                  }
              });
      }
  }



  SignOut(){

    localStorage.removeItem('authToken');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('commonCollege');
    localStorage.removeItem('commonDegree');
    localStorage.removeItem('commonProgram');
    localStorage.removeItem('commonAcademic');
    localStorage.removeItem('commonProgramyear');
    localStorage.removeItem('commonSemester');
    localStorage.removeItem('rolesdetails');

    if(JSON.parse(this.userDetails)[0].loginType=='staff'){
      this.router.navigate(['/authentication/prelogin']);
    }
    if(JSON.parse(this.userDetails)[0].loginType=='regular'){
      this.router.navigate(['/authentication/prelogin']);
      
    }
    if(JSON.parse(this.userDetails)[0].loginType=='student'){
      this.router.navigate(['/authentication/prelogin']);
    }
  }

  updatestudentphoto(){
    const dialogRef = this.dialog.open(UpdatestudentphotoComponent, {
      width: "1000px",
      height: "350px",
      data: {
     
        button: "submit"
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      
    });
  }

  changepassword(){
    const dialogRef = this.dialog.open(ChangepasswordComponent, {
      width: '650px',
      height: '490px',
      data: {
        title: 'Change Password',
        logintype:JSON.parse(this.userDetails)[0].loginType
      }
    });
    dialogRef.afterClosed().subscribe(result => {
     
    });
  }


  viewmore(){
    const dialogRef = this.dialog.open(ViewmoreComponent, {
      width: "1800px",
      height: "430px",
      data: {
     
        button: "submit"
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      
    });
  }
  configureDropdown(){
    const dialogRef = this.dialog.open(ConfigurationdropdownComponent, {
      width: "1000px",
      height: "500px",
      data: {
        title: "Create Staff Profiles!",
        button: "submit"
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      
    });
}

  toggleFullScreen() {
    this.navServices.fullScreen = !this.navServices.fullScreen;
    if (this.navServices.fullScreen) {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
      }
    } else {
      if (!this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }
}
